import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccService {  
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration,private router: Router,private http : HttpClient) {}
  Login(req: any) {
    return this.http
      .post<any>(this.appConfig.UniSystemAPI + 'Account/Login', req, {})
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ChangePass(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniSystemAPI + 'Account/ChangePassword', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  
  AutoLogin(req: any): Observable<any> {
    return this.http
    .get<any>(this.appConfig.UniSystemAPI + 'Account/AutoLoginCheck?token='+req.token+'&username='+req.username+'&cat='+req.cat, {})
    .pipe(
      map((z) => {
        return z;
      })
    );
  }
  GetInfo(req: any, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniSystemAPI + 'Student/GetInfo?Ma_sv='+req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  UpdateHoSoSinhVien(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniSystemAPI + 'Student/Update',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LoadXaByID(req:any,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Student/LoadXaByID?m='+req, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
}
