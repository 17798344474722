import { Component, OnInit, ViewChild, Pipe, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { AccService } from 'src/app/utils/services/acc.service'
import { Location } from '@angular/common';
@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public Service: AccService,
  ) { }
    token:any
    username:any
    cat:any;
  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.username = params.username
      this.token = params.token     
    });     
    this.spinner.show("Đang chuyển trang, vui lòng chờ...")
    if(this.token==undefined||this.username==undefined){
      this.spinner.hide()
      this.toastr.error("Đường link không chính xác !")
    }else{
      let req={
        token:this.token,
        username:this.username,
        cat:"student"
      }
      this.Service.AutoLogin(req).subscribe(z=>{
        this.spinner.hide()
        if(z.Status==1){
          localStorage.setItem('UserInfo', JSON.stringify(z));
          window.location.href = "#/info";
        }else{
          localStorage.removeItem('UserInfo');
          window.location.href = "#/login";
        }
      })
    }
  }

}
