<aside
  #mainSidebar
  class="main-sidebar sidebar-light-primary elevation-4 cus-text"
  style="overflow: visible !important; position: fixed;"
>
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link">
    <img src="assets/img/unilogo.png"  class="brand-image" />
    <span class="brand-text font-weight-light">Student</span>
  </a>

  <div class="sidebar">
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column"  data-widget="treeview" role="menu" data-accordion="false">
        
              <li class="nav-item">
                <a  [routerLink]="['/info']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }"   >
                  <i class="fas fa-search"></i><p>&nbsp;Thông tin cơ bản</p>
                </a>
              </li>
            
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
</aside>
