<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" *ngIf="!isEnabled" (click)="UpdateInfor()" id="Sua"
                    style="float: right;">
                    <i class="fas fa-edit"></i> Sửa
                </button>
                <button class="btn btn-success btn-sm" *ngIf="isEnabled" (click)="Save()" id="Luu"
                    style="float: right;margin-right: 10px">
                    <i class="fas fa-save"></i> Lưu
                </button>
                <button class="btn btn-warning btn-sm" *ngIf="isEnabled" (click)="Huy()" id="Huy"
                    style="float: right;margin-right: 10px">
                    <i class="fas fa-times-circle"></i> Huỷ
                </button>
            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title"><b>Thông tin cá nhân</b></h3>
                <!-- <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                            class="fas fa-minus"></i></button>
                </div> -->
            </div>
            <div class="card-body">
                <form class="form-horizontal" [formGroup]="CaNhan">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <label for="staticEmail" class="col-form-label col-md-12" style="text-align:center">Ảnh đại diện:</label>
                                <label for="staticEmail" class="col-form-label col-md-12" style="text-align:center;font-size:11px"><em>(Ảnh thẻ chân dung 3x4cm)</em></label>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12" id="uploadedImage" style="text-align:center">
                                    <img id="imgFileUpload" [src]="URLImage" type="image" class="img-circle h-170 w170 br-n" style="width:200px;height:200px;margin:20px;border-radius:0%;object-fit: cover;" />
                                   
                                </div>
                                <div class="form-group col-md-12" id="uploadedImage" style="text-align:center" *ngIf="isEnabled">
                                    <span class="hidden-file" style="display:none">
                                        <input type="file"  #fileInput ng2FileSelect [uploader]="uploader"  (change)="checkfile()"/>
                                    </span>
                                    <div class="btn-group" (click)="fileInput.click()" (click)="uploader.clearQueue()">
                                        <button type="button" class="btn btn-primary">
                                            Chọn ảnh đại diện
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-4 ">
                                    <label>Mã sinh viên</label>
                                    <input class="form-control" formControlName="Ma_sv" type="text" />
                                </div>
                                <div class="col-md-4 ">
                                    <label>Họ và tên</label>
                                    <input class="form-control" formControlName="Ho_ten" type="text" />
                                </div>
                                <div class="col-md-4 ">
                                    <label>Ngày sinh</label>
                                    <input class="form-control" formControlName="Ngay_sinh" type="date" />
                                </div>                             
                            </div>
                            <div class="row">
                                <div class="col-md-4 ">
                                    <label>Giới tính</label>
                                    <select class="form-control" formControlName="Gioi_tinh">
                                        <option value="0">Nam</option>
                                        <option value="1">Nữ</option>
                                    </select>
                                </div>
                                <div class="col-md-4 ">
                                    <label class="require">Dân tộc</label>
                                    <select name="" class="form-control" formControlName="Dan_toc" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Dan_toc.errors }">
                                        <option value="">Vui lòng chọn</option>
                                        <option *ngFor="let i of ListDanToc" value="{{ i.ID_dan_toc }}">{{i.Dan_toc}}
                                        </option>
                                    </select>
                                    <div *ngIf="checkvalue.Dan_toc.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Dan_toc.errors.required">
                                            Dân tộc không được để trống
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <label>Số sổ BH</label>
                                    <input class="form-control" formControlName="So_so_BHYT" type="text" />
                                </div>                        
                            </div>
                            <div class="row" style="margin-top: 10px;">
                                <div class="col-md-4 ">
                                    <label class="require">CCCD</label>
                                    <input class="form-control" formControlName="CMND" type="text" required
                                        [ngClass]="{ 'is-invalid': checkvalue.CMND.errors }" />
                                    <div *ngIf="checkvalue.CMND.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.CMND.errors.required">
                                            Số CCCD không được để trống.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <label class="require">Nơi cấp CCCD</label>
                                    <select class="form-control" formControlName="Noi_cap_CMND" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Noi_cap_CMND.errors }">
                                        <option value="">Vui lòng chọn</option>
                                        <option *ngFor="let i of ListTinh" value="{{ i.ID_tinh }}">{{i.Ten_tinh}}
                                        </option>
                                    </select>
                                    <div *ngIf="checkvalue.Noi_cap_CMND.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Noi_cap_CMND.errors.required">
                                            Nơi cấp CCCD không được để trống.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <label class="require">Ngày cấp CCCD</label>
                                    <input class="form-control" formControlName="Ngay_cap_CMND" type="date" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Ngay_cap_CMND.errors }" />
                                    <div *ngIf="checkvalue.Ngay_cap_CMND.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Ngay_cap_CMND.errors.required">
                                            Ngày cấp CCCD không được để trống.
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                            <div class="row" style="margin-top: 10px;">
                                <div class="col-md-4 ">
                                    <label class="require">Tỉnh nơi sinh</label>
                                    <select name="" class="form-control" formControlName="Tinh_noi_sinh" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Tinh_noi_sinh.errors }">
                                        <option value="">Vui lòng chọn</option>
                                        <option *ngFor="let i of ListTinh" value="{{ i.ID_tinh }}">{{i.Ten_tinh}}
                                        </option>
                                    </select>
                                    <div *ngIf="checkvalue.Tinh_noi_sinh.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Tinh_noi_sinh.errors.required">
                                            Tỉnh nơi sinh không được để trống
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <label class="require">Quê quán</label>
                                    <input class="form-control" formControlName="Que_quan" type="text" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Que_quan.errors }" />
                                    <div *ngIf="checkvalue.Que_quan.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Que_quan.errors.required">
                                            Quê quán không được để trống
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <label class="require">Quốc tịch</label>
                                    <select name="" class="form-control" formControlName="Quoc_tich" required
                                        [ngClass]="{ 'is-invalid': checkvalue.Quoc_tich.errors }">
                                        <option value="">Vui lòng chọn</option>
                                        <option *ngFor="let i of ListQuocTich" value="{{ i.ID_quoc_tich }}">
                                            {{i.Quoc_tich}}
                                        </option>
                                    </select>
                                    <div *ngIf="checkvalue.Quoc_tich.errors" class="invalid-feedback">
                                        <div *ngIf="checkvalue.Quoc_tich.errors.required">
                                            Quốc tịch không được để trống
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                         
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3 ">
                            <label class="require">Nơi thường trú</label>
                            <input class="form-control" formControlName="Noi_thuong_tru" type="text" required
                                [ngClass]="{ 'is-invalid': checkvalue.Noi_thuong_tru.errors }" />
                            <div *ngIf="checkvalue.Noi_thuong_tru.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Noi_thuong_tru.errors.required">
                                    Nơi thường trú không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">Tỉnh/TP</label>
                            <select name="" class="form-control" formControlName="Tinh_thuong_tru" required
                                [ngClass]="{ 'is-invalid': checkvalue.Tinh_thuong_tru.errors }" (change)="TinhChange()">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListTinh" value="{{ i.ID_tinh }}">{{i.Ten_tinh}}</option>
                            </select>
                            <div *ngIf="checkvalue.Tinh_thuong_tru.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Tinh_thuong_tru.errors.required">
                                    Tỉnh thường trú không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">Quận/Huyện</label>
                            <select name="" class="form-control" formControlName="Huyen_thuong_tru" required
                                [ngClass]="{ 'is-invalid': checkvalue.Huyen_thuong_tru.errors }"
                                (change)="HuyenChange()">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListHuyen2" value="{{ i.ID_huyen }}">{{i.Ten_huyen}}</option>
                            </select>
                            <div *ngIf="checkvalue.Huyen_thuong_tru.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Huyen_thuong_tru.errors.required">
                                    Huyện thường trú không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label>Xã/Phường</label>
                            <select name="" class="form-control" formControlName="Xa_thuong_tru">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListXa2" value="{{ i.ID_xa }}">{{i.Ten_xa}}</option>
                            </select>                        
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3 ">
                            <label class="require">Tôn giáo</label>
                            <select name="" class="form-control" formControlName="Ton_giao" required
                                [ngClass]="{ 'is-invalid': checkvalue.Ton_giao.errors }">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListTonGiao" value="{{ i.ID_ton_giao }}">{{i.Ton_giao}}
                                </option>
                            </select>
                            <div *ngIf="checkvalue.Ton_giao.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Ton_giao.errors.required">
                                    Tôn giáo không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">Xuất thân</label>
                            <select name="" class="form-control" formControlName="Xuat_than" required
                                [ngClass]="{ 'is-invalid': checkvalue.Xuat_than.errors }">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListTPXuatThan" value="{{ i.ID_thanh_phan_xuat_than }}">
                                    {{i.Ten_thanh_phan}}</option>
                            </select>
                            <div *ngIf="checkvalue.Xuat_than.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Xuat_than.errors.required">
                                    Xuất thân không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label>Ngày vào đoàn</label>
                            <input class="form-control" formControlName="Ngay_vao_doan" type="date" />
                        </div>
                        <div class="col-md-3 ">
                            <label>Ngày vào đảng</label>
                            <input class="form-control" formControlName="Ngay_vao_dang" type="date" />
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3 ">
                            <label>Đối tượng CS</label>
                            <select name="" class="form-control" formControlName="Doi_tuong_cs">
                                <option value="">Vui lòng chọn</option>
                                <!-- <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{i.name}}</option> -->
                            </select>
                        </div>
                        <div class="col-md-3 ">
                            <label>Đối tượng trợ cấp</label>
                            <select name="" class="form-control" formControlName="Doi_tuong_tro_cap">
                                <option value="">Vui lòng chọn</option>
                                <!-- <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{i.name}}</option> -->
                            </select>
                        </div>
                        <div class="col-md-3 ">
                            <label>Nhóm đối tượng</label>
                            <select name="" class="form-control" formControlName="Nhom_doi_tuong">
                                <option value="">Vui lòng chọn</option>
                                <!-- <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{i.name}}</option> -->
                            </select>
                        </div>
                        <div class="col-md-3 ">
                            <label>Loại khuyết tật</label>
                            <select name="" class="form-control" formControlName="Loai_khuyet_tat">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListLoaiKhuyetTat" value="{{ i.ID_loai_khuyet_tat }}">
                                    {{i.Loai_khuyet_tat}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3 ">
                            <label>SĐT bàn</label>
                            <input class="form-control" formControlName="SDT_ban" type="text"
                                (keyup)="isNumber($event,'SDT_ban')" />
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">SĐT di động</label>
                            <input class="form-control" formControlName="SDT_di_dong" type="text"
                                (keyup)="isNumber($event,'SDT_di_dong')" required
                                [ngClass]="{ 'is-invalid': checkvalue.SDT_di_dong.errors }" />
                            <div *ngIf="checkvalue.SDT_di_dong.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.SDT_di_dong.errors.required">
                                    SĐT di động không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">Email</label>
                            <input class="form-control" formControlName="Email" type="email" required
                                [ngClass]="{ 'is-invalid': checkvalue.Email.errors }" />
                            <div *ngIf="checkvalue.Email.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Email.errors.required">
                                    Email không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label>Số TK ATM</label>
                            <input class="form-control" formControlName="So_TK_ATM" readonly type="text" />
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">

                        <div class="col-md-3 ">
                            <label class="require">Địa chỉ báo tin</label>
                            <input class="form-control" formControlName="Dia_chi_bao_tin" type="text" required
                                [ngClass]="{ 'is-invalid': checkvalue.Dia_chi_bao_tin.errors }" />
                            <div *ngIf="checkvalue.Dia_chi_bao_tin.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Dia_chi_bao_tin.errors.required">
                                    Địa chỉ báo tin không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label class="require">Nơi ở hiện nay</label>
                            <input class="form-control" formControlName="Noi_o_hien_nay" type="text" required
                                [ngClass]="{ 'is-invalid': checkvalue.Noi_o_hien_nay.errors }" />
                            <div *ngIf="checkvalue.Noi_o_hien_nay.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Noi_o_hien_nay.errors.required">
                                    Nơi ở hiện nay không được để trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <label >Số thẻ BHYT</label>
                            <input class="form-control" formControlName="So_the_BHYT" type="text" />                           
                        </div>
                        <div class="col-md-3 ">
                            <label>Số sổ hộ khẩu</label>
                            <input class="form-control" formControlName="So_so_ho_khau" type="text" />
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title"><b>Thông tin gia đình</b></h3>
            </div>
            <div class="card-body">
                <form class="form-horizontal" [formGroup]="GiaDinh">
                    <div class="row">
                        <div class="col-md-3 ">
                            <label >Họ tên bố</label>
                            <input class="form-control" formControlName="Ho_ten_bo" type="text"/>
                            <!-- <div *ngIf="checkvalue2.Ho_ten_bo.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue2.Ho_ten_bo.errors.required">
                                    Họ tên bố không được để trống
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-3 ">
                            <label >Năm sinh bố</label>
                            <input class="form-control" formControlName="Nam_sinh_bo" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Nghề nghiệp bố</label>
                            <input class="form-control" formControlName="Nghe_nghiep_bo" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Quốc tịch bố</label>
                            <select name="" class="form-control" formControlName="Quoc_tich_bo">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListQuocTich" value="{{ i.ID_quoc_tich }}">{{i.Quoc_tich}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 ">
                            <label >Dân tộc bố</label>
                            <select name="" class="form-control" formControlName="Dan_toc_bo">
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListDanToc" value="{{ i.ID_dan_toc }}">{{i.Dan_toc}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 ">
                            <label >Tôn giáo bố</label>
                            <input class="form-control" formControlName="Ton_giao_bo" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Hộ khẩu TT bố</label>
                            <input class="form-control" formControlName="Ho_khau_TT_bo" type="text" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 ">
                            <label >Họ tên mẹ</label>
                            <input class="form-control" formControlName="Ho_ten_me" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Năm sinh mẹ</label>
                            <input class="form-control" formControlName="Nam_sinh_me" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Nghề nghiệp mẹ</label>
                            <input class="form-control" formControlName="Nghe_nghiep_me" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Quốc tịch mẹ</label>
                            <select name="" class="form-control" formControlName="Quoc_tich_me" >
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListQuocTich" value="{{ i.ID_quoc_tich }}">{{i.Quoc_tich}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 ">
                            <label >Dân tộc mẹ</label>
                            <select name="" class="form-control" formControlName="Dan_toc_me" >
                                <option value="">Vui lòng chọn</option>
                                <option *ngFor="let i of ListDanToc" value="{{ i.ID_dan_toc }}">{{i.Dan_toc}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 ">
                            <label >Tôn giáo mẹ</label>
                            <input class="form-control" formControlName="Ton_giao_me" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label >Hộ khẩu TT mẹ</label>
                            <input class="form-control" formControlName="Ho_khau_TT_me" type="text" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 ">
                            <label>Họ tên giám hộ</label>
                            <input class="form-control" formControlName="Ho_ten_giam_ho" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label>Năm sinh giám hộ</label>
                            <input class="form-control" formControlName="Nam_sinh_giam_ho" type="text" />
                        </div>
                        <div class="col-md-3 ">
                            <label>Nghề nghiệp giám hộ</label>
                            <input class="form-control" formControlName="Nghe_nghiep_giam_ho" type="text" />
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</section>