import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl } from '@angular/forms';
// import { DashBoardService } from 'src/app/utils/services/dash-board.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  Tong_ke_hoach_di_thuc_te:any = 0
  Tong_Ke_hoach_giao_vien_gioi:any = 0
  Tong_Ke_hoach_thao_giang_du_gio:any = 0
  Token:any
  constructor(
    private modalService: NgbModal,
    // private DashBoardService: DashBoardService,
    public router: Router,
  ) {}
  public com: common
  ngOnInit() {
    this.com = new common(this.router)
    this.com.CheckLogin();
    var a = this.com.getUserinfo()
    this.Token = a.Token
    this.getList(a.Token)
  }
  getList(token) {
    // this.DashBoardService.getList(token).subscribe((z) => {
    //   console.log(z)
    //   this.Tong_ke_hoach_di_thuc_te = z.Data.Tong_ke_hoach_di_thuc_te;
    //   this.Tong_Ke_hoach_giao_vien_gioi = z.Data.Tong_Ke_hoach_giao_vien_gioi;
    //   this.Tong_Ke_hoach_thao_giang_du_gio = z.Data.Tong_Ke_hoach_thao_giang_du_gio;
     
 
  }
}
