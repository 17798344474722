<section class="content-header" style="padding: 5px !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item active">
            <i class="fas fa-home"></i> &nbsp;Trang chủ
          </li>
          <div class=""></div>
        </ol>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-4 col-6">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="inner">
            <h3>{{Tong_ke_hoach_di_thuc_te}}</h3>

            <p>Tổng Kế hoạch đi thực tế</p>
          </div>
          <div class="icon">
            <i class="fas fa-plane-departure"></i>
          </div>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-4 col-6">
        <!-- small box -->
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{Tong_Ke_hoach_giao_vien_gioi}}</h3>

            <p>Tổng kế hoạch hội thi giáo viên giỏi</p>
          </div>
          <div class="icon">
            <i class="fas fa-chalkboard-teacher"></i>
          </div>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-4 col-6">
        <!-- small box -->
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>{{Tong_Ke_hoach_thao_giang_du_gio}}</h3>

            <p>Tổng kế hoạch thao giảng, dự giờ</p>
          </div>
          <div class="icon">
            <i class="fas fa-user-graduate"></i>
          </div>
        </div>
      </div>
      <!-- ./col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
