<!-- Navbar -->
<nav class="main-header navbar navbar-expand bg-info navbar-light" style="height: 55px;">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()"
        ><i class="fas fa-bars cus-icon"></i
      ></a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <app-user-dropdown-menu></app-user-dropdown-menu>
    <li class="nav-item">
      <a class="nav-link" (click)="logout()">
        <i class="fas fa-power-off cus-icon"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->
