import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;

  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    private appService: AppService) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      search: new FormControl(null),
    });
  }

  logout() {
    var r = confirm("Bạn có muốn đăng xuất không?");
    if (r) {
      if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
        this.appService.logout();
      }

      if (this.appConfig.AuthorizeType == 2) {
        localStorage.removeItem('UserInfo');
        window.location.href= this.appConfig.CAS.Logout;
      }
    }
  }
}
