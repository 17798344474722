<footer class="main-footer">
  <div class="float-right d-none d-sm-block">
    
  </div>
  <strong
    >Copyright &copy; 
    <a [href]="url">{{text}}</a></strong
  >

</footer>
