import { InjectionToken } from '@angular/core';

export interface AppConfiguration {
  production: boolean;
  UniLeader: string;
  UniSystemAPI: string;
  AuthorizeType: number;
  sso: {
    ssoServer: string;
    client_id: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    code_challenge_method: string;
    response_mode: string;
  };
  CAS: {
    ServerUrl: string;
    Service: string;
    Logout: string;
  };
  FooterURL: string;
  FooterText: string;
}

export const AppConfig = new InjectionToken<AppConfiguration>('@@appConfiguration');
