
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AllService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getHe(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'He/Danh_sach_he', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getKhoa(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Khoa/Danh_sach_khoa', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getKhoaHoc(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Core/KhoaHoc_Load_List', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getChuyenNganh(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'ChuyenNganh/Danh_sach_chuyen_nganh', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getLop(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Lop/Danh_sach_lop_dang_hoc', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Tinh(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Tinh/Danh_sach_tinh', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Huyen(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Huyen/Danh_sach_huyen', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Xa(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Xa/Danh_sach_xa', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  NhomDoiTuong(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'NhomDoiTuong/Danh_sach_nhom_doi_tuong', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  DanToc(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'DanToc/Danh_sach_dan_toc', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  QuocTich(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'QuocTich/Danh_sach_quoc_tich', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  TonGiao(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'TonGiao/Danh_sach_ton_giao', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  ThanhPhanXuatThan(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'ThanhPhanXuatThan/Danh_sach_thanh_phan', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  LoaiKhuyetTat(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'LoaiKhuyetTat/Danh_sach_loai_khuyet_tat', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  
}
