import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { VerificationComponent } from './pages/login/verification/verification.component';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AutoLoginComponent } from './pages/auto-login/auto-login.component';
import { InfoComponent } from './pages/info/info.component';


const routes: Routes = [
  {
    path: 'portal',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'infor',
        component: InfoComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: '',
    component: VerificationComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'auto-login',
    component: AutoLoginComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    BrowserModule, HttpClientModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
