import { ResponseLogin } from 'src/app/models/output.model/ResponseLogin';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

export class common {
  public LoginResult: ResponseLogin;
  public PortalResult: any;
  public cookieService: CookieService;
  public CheckLogin() {
    this.LoginResult = new ResponseLogin();
    this.LoginResult = this.getUserinfo();
    if (this.LoginResult == null) {
      this.router.navigate(['/login']);
    }
  }
  constructor(private router: Router) {}
  public getUserinfo() {
    this.LoginResult = JSON.parse(localStorage.getItem('UserInfo'));
    return this.LoginResult;
  }
  login() {
    this.router.navigate(['/portal/infor']);
  }
  logout() {
    localStorage.removeItem('UserInfo');
    this.router.navigate(['/login']);
  }

  redirectToLoginSystem() {
    localStorage.removeItem("UserInfo");
    this.router.navigate(["/login"]);
  }
}
