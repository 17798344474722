import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccService } from 'src/app/utils/services/acc.service'
import { AllService } from 'src/app/utils/services/all.service'
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class InfoComponent implements OnInit {
  Token: any;
  ListTinh: any;
  ListHuyen: any;
  ListHuyen2: any;
  ListXa: any;
  ListXa2: any;
  ListDanToc: any;
  ListQuocTich: any;
  ListTonGiao: any;
  ListTPXuatThan: any;
  ListLoaiKhuyetTat: any;
  ID_sv: any
  Image: any
  URLImage:any
  dataBase64fist:any
  dataBase64last:any
  imagename:any
  Ma_sv:any
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public Service: AccService,
    public AllService: AllService,
  ) { }
  public com: common;
  isEnabled: any = false;
  CaNhan = new FormGroup({
    Ma_sv: new FormControl(null),
    Ho_ten: new FormControl(null),
    Ngay_sinh: new FormControl(null),
    Gioi_tinh: new FormControl(null),
    Tinh_noi_sinh: new FormControl("", [Validators.required]),
    Que_quan: new FormControl(null, [Validators.required]),
    Quoc_tich: new FormControl("", [Validators.required]),
    Dan_toc: new FormControl("", [Validators.required]),
    Ton_giao: new FormControl(null, [Validators.required]),
    Xuat_than: new FormControl(null, [Validators.required]),
    Ngay_vao_doan: new FormControl(null),
    Ngay_vao_dang: new FormControl(null),
    Noi_thuong_tru: new FormControl(null, [Validators.required]),
    Tinh_thuong_tru: new FormControl("", [Validators.required]),
    Huyen_thuong_tru: new FormControl("", [Validators.required]),
    Xa_thuong_tru: new FormControl(" "),
    Doi_tuong_cs: new FormControl(null),
    Doi_tuong_tro_cap: new FormControl(null),
    Nhom_doi_tuong: new FormControl(null),
    SDT_ban: new FormControl(null),
    SDT_di_dong: new FormControl(null, [Validators.required]),
    Email: new FormControl(null, [Validators.required]),
    CMND: new FormControl(null, [Validators.required]),
    Noi_cap_CMND: new FormControl(null, [Validators.required]),
    Ngay_cap_CMND: new FormControl(null, [Validators.required]),
    So_TK_ATM: new FormControl(null),
    So_so_bh: new FormControl(null),
    Loai_khuyet_tat: new FormControl(null),
    Dia_chi_bao_tin: new FormControl(null, [Validators.required]),
    Noi_o_hien_nay: new FormControl(null, [Validators.required]),
    So_so_BHYT: new FormControl(null),
    So_the_BHYT: new FormControl(null),
    So_so_ho_khau: new FormControl(null),
  })
  GiaDinh = new FormGroup({
    Ho_ten_bo: new FormControl(null),
    Nam_sinh_bo: new FormControl(null),
    Nghe_nghiep_bo: new FormControl(null),
    Quoc_tich_bo: new FormControl(null),
    Dan_toc_bo: new FormControl(null),
    Ton_giao_bo: new FormControl(null),
    Ho_ten_me: new FormControl(null),
    Nam_sinh_me: new FormControl(null),
    Nghe_nghiep_me: new FormControl(null),
    Quoc_tich_me: new FormControl(null),
    Dan_toc_me: new FormControl(null),
    Ton_giao_me: new FormControl(null),
    Ho_ten_giam_ho: new FormControl(null),
    Nam_sinh_giam_ho: new FormControl(null),
    Nghe_nghiep_giam_ho: new FormControl(null),
    Ho_khau_TT_bo: new FormControl(null),
    Ho_khau_TT_me: new FormControl(null),
  })
  ngOnInit(): void {
    this.CaNhan.disable()
    this.GiaDinh.disable()
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.spinner.show()
    this.Service.GetInfo(a.Info.UserName, a.Token).subscribe(z => {
      if (z.Status == 1) {
        if (z.length == 0) {
          localStorage.removeItem('UserInfo');
          this.spinner.hide()
          window.location.href = "/login";
        } else {
          this.ListTinh = z.Tinh
          this.ListHuyen = z.Huyen
          this.ListHuyen2 = z.Huyen
          this.ListQuocTich = z.QuocTich
          this.ListDanToc = z.DanToc
          this.ListTonGiao = z.TonGiao
          this.ListTPXuatThan = z.ThanhPhanXuatThan
          this.ListLoaiKhuyetTat = z.LoaiKhuyetTat
          if (z.Data[0].ID_huyen_tt != "") {
            this.Service.LoadXaByID(z.Data[0].ID_huyen_tt, a.Token).subscribe(z => {
              this.ListXa2 = z.Xa
            })
          }
          this.SetValue(z.Data[0])
          this.URLImage = z.Data[0].ImageURL; 
          this.ID_sv = z.Data[0].ID_sv
          this.Ma_sv = z.Data[0].Ma_sv
        }
      } else {
        this.spinner.hide()
        this.toastr.error(z.Message)
      }
    }, (err) => {
      this.spinner.hide();
      console.log(err)
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
    })
  }

  public uploader: FileUploader = new FileUploader({
    disableMultipart: true,
    itemAlias: 'attachment'
  });
  checkfile() {  
    console.log(this.uploader.queue)
    if (this.uploader.queue[0].file.type != 'image/jpeg') {
      this.toastr.warning("Định dạng ảnh là jpg");
      this.Image = ""
    } else
      if (this.uploader.queue[0].file.size > 2149755) {
        this.toastr.warning("Kích thước ảnh phải nhỏ hơn 2MB");
      } else {
        let fileReader = new FileReader()  
        this.imagename=this.uploader.queue[0].file.name
        let dataBase64:any
        fileReader.onload = (_event) => { 
          dataBase64= fileReader.result;
          this.dataBase64last=dataBase64.split("base64,")[1];
          this.dataBase64fist=dataBase64.split("base64,")[0];
          this.URLImage = fileReader.result; 
        }     
        fileReader.readAsDataURL(this.uploader.queue[0]._file);
      }
  }
  get checkvalue() {
    return this.CaNhan.controls
  }
  get checkvalue2() {
    return this.GiaDinh.controls
  }
  TinhChange() {
    var id_tinh = this.CaNhan.value.Tinh_thuong_tru
    if (id_tinh == "") {
      this.ListHuyen2 = this.ListHuyen
    } else {
      this.ListHuyen2 = this.ListHuyen.filter(i => i.ID_tinh == id_tinh);
    }
    this.HuyenChange()
  }
  HuyenChange() {
    var id_huyen = this.CaNhan.value.Huyen_thuong_tru
    if (id_huyen != "") {

      this.Service.LoadXaByID(id_huyen, this.Token).subscribe(z => {
        this.ListXa2 = z.Xa
      })
    }

  }
  isNumber(value: any, formControl) {
    // (keyup)="isNumber($event,'CMND')"   
    value = value.target.value
    if ((value != null) && (value !== '') && isNaN(Number(value.toString()))) {
      var num = (this.CaNhan.get(formControl).value)
      this.CaNhan.get(formControl).setValue(num.substring(0, num.toString().length - 1));
    };
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  SetValue(Data) {
    this.spinner.show()
    // this.ListXa2 = this.ListXa;
    if ((Data.Ngay_sinh).substring(6, 10) < 1900) {
      Data.Ngay_sinh = null
    }
    if ((Data.Ngay_vao_doan).substring(6, 10) < 1900) {
      Data.Ngay_vao_doan = null
    }
    if ((Data.Ngay_vao_dang).substring(6, 10) < 1900) {
      Data.Ngay_vao_dang = null
    }
    if ((Data.Ngay_cap_CMND).substring(6, 10) < 1900) {
      Data.Ngay_cap_CMND = null
    }
    this.CaNhan.setValue({
      Ma_sv: Data.Ma_sv,
      Ho_ten: Data.Ho_ten,
      Ngay_sinh: Data.Ngay_sinh != null ? Data.Ngay_sinh.split("/").reverse().join("-") : null,
      Gioi_tinh: Data.ID_gioi_tinh,
      Tinh_noi_sinh: Data.ID_tinh_ns == "0" ? "" : Data.ID_tinh_ns,
      Que_quan: Data.Que_quan,
      Quoc_tich: Data.ID_quoc_tich == 0 ? "" : Data.ID_quoc_tich,
      Dan_toc: Data.ID_dan_toc == 0 ? "" : Data.ID_dan_toc,
      Ton_giao: Data.Ton_giao != null ? (Data.Ton_giao == "0" ? "" : Data.Ton_giao) : "",
      Xuat_than: Data.ID_thanh_phan_xuat_than == 0 ? "" : Data.ID_thanh_phan_xuat_than,
      Ngay_vao_doan: Data.Ngay_vao_doan != null ? Data.Ngay_vao_doan.split("/").reverse().join("-") : null,
      Ngay_vao_dang: Data.Ngay_vao_dang != null ? Data.Ngay_vao_dang.split("/").reverse().join("-") : null,
      Noi_thuong_tru: Data.Dia_chi_tt,
      Tinh_thuong_tru: Data.ID_tinh_tt != "0" ? Data.ID_tinh_tt : "",
      Huyen_thuong_tru: Data.ID_huyen_tt != "0" ? Data.ID_huyen_tt : "",
      Xa_thuong_tru: Data.Xa_phuong_tt,
      Doi_tuong_cs: 0,
      Doi_tuong_tro_cap: Data.ID_doi_tuong_TC,
      Nhom_doi_tuong: Data.ID_nhom_doi_tuong,
      SDT_ban: Data.Dien_thoai_NR,
      SDT_di_dong: Data.Dienthoai_canhan,
      Email: Data.Email,
      CMND: Data.CMND,
      Noi_cap_CMND: Data.ID_noi_cap_CMND == "0" ? "" : Data.ID_noi_cap_CMND,
      Ngay_cap_CMND: Data.Ngay_cap_CMND != null ? Data.Ngay_cap_CMND.split("/").reverse().join("-") : null,
      So_TK_ATM: Data.So_TK_ATM,
      So_so_bh: Data.So_so_bao_hiem,
      Loai_khuyet_tat: Data.ID_loai_khuyet_tat,
      Dia_chi_bao_tin: Data.Dia_chi_bao_tin,
      Noi_o_hien_nay: Data.NoiO_hiennay,
      So_so_BHYT: Data.So_so_bao_hiem,
      So_the_BHYT: Data.So_the_BHYT,
      So_so_ho_khau: Data.So_so_ho_khau,
    });
    this.GiaDinh.setValue({
      Ho_ten_bo: Data.Ho_ten_cha,
      Nam_sinh_bo: Data.Namsinh_cha,
      Nghe_nghiep_bo: Data.Hoat_dong_XH_CT_cha,
      Quoc_tich_bo: Data.ID_quoc_tich_cha == 0 ? "" : Data.ID_quoc_tich_cha,
      Dan_toc_bo: Data.ID_dan_toc_cha == 0 ? "" : Data.ID_dan_toc_cha,
      Ton_giao_bo: Data.Ton_giao_cha,
      Ho_khau_TT_bo: Data.Ho_khau_TT_bo,
      Ho_ten_me: Data.Ho_ten_me,
      Nam_sinh_me: Data.Namsinh_me,
      Nghe_nghiep_me: Data.hoat_dong_XH_CT_me,
      Quoc_tich_me: Data.ID_quoc_tich_me == 0 ? "" : Data.ID_quoc_tich_me,
      Dan_toc_me: Data.ID_dan_toc_me == 0 ? "" : Data.ID_dan_toc_me,
      Ton_giao_me: Data.Ton_giao_me,
      Ho_khau_TT_me: Data.Ho_khau_TT_me,
      Ho_ten_giam_ho: Data.Ho_ten_giam_ho,
      Nam_sinh_giam_ho: Data.NamSinh_giam_ho,
      Nghe_nghiep_giam_ho: Data.hoat_dong_XH_CT_giam_ho,
    })
    this.spinner.hide()
  }
  UpdateInfor() {
    this.isEnabled = true;
    this.CaNhan.enable()
    this.CaNhan.get('Ma_sv').disable()
    this.CaNhan.get('Ho_ten').disable()
    this.CaNhan.get('Ngay_sinh').disable()
    this.CaNhan.get('Doi_tuong_cs').disable()
    this.CaNhan.get('Doi_tuong_tro_cap').disable()
    this.CaNhan.get('Nhom_doi_tuong').disable()
    this.GiaDinh.enable()
  }
  CheckValidate() {
    let bool1 = true;
    let bool2 = true;
    if (this.CaNhan.invalid) {
      bool1 = false
    }
    if (this.GiaDinh.invalid) {
      bool2 = false
    }
    return bool1 && bool2
  }
  Save() {
    if (this.CheckValidate()) {
      var req = {
        ID_sv: this.ID_sv,
        Ma_sv: this.Ma_sv,
        Ho_ten: this.CaNhan.value.Ho_ten,
        Ngay_sinh: this.CaNhan.value.Ngay_sinh,
        ID_gioi_tinh: this.CaNhan.value.Gioi_tinh,
        CMND: this.CaNhan.value.CMND,
        ID_noi_cap_CMND: this.CaNhan.value.Noi_cap_CMND,
        Ngay_cap_CMND: this.CaNhan.value.Ngay_cap_CMND,
        So_so_BHYT: this.CaNhan.value.So_so_BHYT,
        ID_tinh_ns: this.CaNhan.value.Tinh_noi_sinh,
        Que_quan: this.CaNhan.value.Que_quan,
        ID_quoc_tich: this.CaNhan.value.Quoc_tich,
        ID_dan_toc: this.CaNhan.value.Dan_toc,
        Dia_chi_tt: this.CaNhan.value.Noi_thuong_tru,
        ID_tinh_tt: this.CaNhan.value.Tinh_thuong_tru,
        ID_huyen_tt: this.CaNhan.value.Huyen_thuong_tru,
        Xa_phuong_tt: this.CaNhan.value.Xa_thuong_tru,
        Ton_giao: this.CaNhan.value.Ton_giao,
        ID_thanh_phan_xuat_than: this.CaNhan.value.Xuat_than,
        Ngay_vao_doan: this.CaNhan.value.Ngay_vao_doan,
        Ngay_vao_dang: this.CaNhan.value.Ngay_vao_dang,
        Doi_tuong_cs: this.CaNhan.value.Doi_tuong_cs,
        Doi_tuong_tro_cap: this.CaNhan.value.Doi_tuong_tro_cap,
        ID_nhom_doi_tuong: this.CaNhan.value.Nhom_doi_tuong,
        ID_loai_khuyet_tat: this.CaNhan.value.Loai_khuyet_tat,
        Dien_thoai_NR: this.CaNhan.value.SDT_ban,
        Dienthoai_canhan: this.CaNhan.value.SDT_di_dong,
        Email: this.CaNhan.value.Email,
        So_TK_ATM: this.CaNhan.value.So_TK_ATM,
        Dia_chi_bao_tin: this.CaNhan.value.Dia_chi_bao_tin,
        NoiO_hiennay: this.CaNhan.value.Noi_o_hien_nay,
        So_the_BHYT: this.CaNhan.value.So_the_BHYT,
        So_so_ho_khau: this.CaNhan.value.So_so_ho_khau,
        Ho_ten_cha: this.GiaDinh.value.Ho_ten_bo,
        Namsinh_cha: this.GiaDinh.value.Nam_sinh_bo,
        Hoat_dong_XH_CT_cha: this.GiaDinh.value.Nghe_nghiep_bo,
        ID_quoc_tich_cha: this.GiaDinh.value.Quoc_tich_bo,
        ID_dan_toc_cha: this.GiaDinh.value.Dan_toc_bo,
        Ton_giao_cha: this.GiaDinh.value.Ton_giao_bo,
        Ho_ten_me: this.GiaDinh.value.Ho_ten_me,
        Namsinh_me: this.GiaDinh.value.Nam_sinh_me,
        Hoat_dong_XH_CT_me: this.GiaDinh.value.Nghe_nghiep_me,
        ID_quoc_tich_me: this.GiaDinh.value.Quoc_tich_me,
        ID_dan_toc_me: this.GiaDinh.value.Dan_toc_me,
        Ton_giao_me: this.GiaDinh.value.Ton_giao_me,
        Ho_ten_giam_ho: this.GiaDinh.value.Ho_ten_giam_ho,
        NamSinh_giam_ho: this.GiaDinh.value.Nam_sinh_giam_ho,
        hoat_dong_XH_CT_giam_ho: this.GiaDinh.value.Nghe_nghiep_giam_ho,
        Ho_khau_TT_bo: this.GiaDinh.value.Ho_khau_TT_bo,
        Ho_khau_TT_me: this.GiaDinh.value.Ho_khau_TT_me,
        ImageName:this.imagename,
        ImageData:this.dataBase64last
      }
      
      this.spinner.show()
      this.Service.UpdateHoSoSinhVien(req, this.Token).subscribe(z => {
        this.spinner.hide()
        if (z.Status == 1) {
          this.toastr.success("Cập nhật thông tin thành công")
          this.CaNhan.disable()
          this.GiaDinh.disable()
          this.isEnabled = false
        } else {
          this.toastr.error("Cập nhật thông tin thất bại")
        }

      })

    } else {
      this.toastr.warning("Vui lòng nhập đủ thông tin cần thiết")
    }

  }
  Huy() {
    this.isEnabled = false
    this.CaNhan.disable()
    this.GiaDinh.disable()
  }

}
