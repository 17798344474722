import { Component, OnInit, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration
  ) {}
  url: string = '';
  text: string = '';
  ngOnInit() {
    console.log(this.appConfig)
    this.url = this.appConfig.FooterURL ?? 'http://thienan.vn';
    this.text = this.appConfig.FooterText ?? 'Thiên An';
  }
}
