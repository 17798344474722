import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { common } from "src/app/app.common";
import { CasService } from "src/app/utils/services/cas.service";
import { AppConfig, AppConfiguration } from "src/configuration";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit {
  public com: common;
  Token: any;
  code: any = null;
  ticket: any = null;
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public casService: CasService
  ) {}
  ngOnInit() {
    this.com = new common(this.router);
    let userData = this.com.getUserinfo();
    if (userData == null) {
      this.route.queryParams.subscribe((params) => {
        this.code = params.code;
          this.ticket = params.ticket;
        this.spinner.show();
  
        if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
          // đăng nhập sử dụng UniSystemAPI đối với hệ thống không sử dụng cơ chế SSO
          this.spinner.hide();
          this.com.redirectToLoginSystem();
        }
  
        if (this.appConfig.AuthorizeType == 2) {
          // Đăng nhập qua CAS
          if (this.ticket == null) {
            this.casService.RedirectCAS();
          } else {
            this.spinner.show();
            this.casService.validate(this.ticket).subscribe((z) => {
              this.spinner.hide();
              if (z.Status == 1) {
                this.spinner.hide();
                localStorage.setItem('UserInfo', JSON.stringify(z));
                this.com.login();
              } else {
                this.toastr.warning(z.Message);
              }
            });
          }
        }
      });
    }
    else{
      this.com.login();
    }
    
  }
}
